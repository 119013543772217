class BatchAction {
  constructor({
    /** 触发的事件 */
    asyncAction,
    /** 触发间隔 */
    duration = 1000,
    /** 参数合集格式化 */
    paramsFormat = params => params.map(item => item),
    /** 事件结束回调 */
    callback = params => params,
  }) {
    /** 触发的事件 */
    this.asyncAction = asyncAction;
    /** 触发间隔 */
    this.duration = duration;
    this.params = [];
    this.paramsFormat = paramsFormat;
    this.callback = callback;
    this.timer = null;
  }

  addParams = (param) => {
    this.params.push(param);
  }

  // 触发事件
  targetAction = (param) => {
    this.addParams(param);
    if (!this.timer) {
      // 如果没有计时器，则创建一个计时器
      this.timer = setTimeout(this.doAction, this.duration);
    }
  }

  doAction = () => {
    this.timer = null;

    const params = this.paramsFormat(this.params);

    this.params = [];
    this.asyncAction(params)
      .then(this.callback)
      .catch((err) => {
        console.log(err);
      });
  }
}

export default BatchAction;
