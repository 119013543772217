
/* eslint-disable  no-new  */
import AlloyFinger from 'alloyfinger';
import Transform from 'css3transform';
import To from './to';
const transformImg = (el) => {
  if (!el) {
    return;
  }
  /** 最大放大比例 */
  const MAX_SCALE = 3;
  /** 最小放大比例 */
  const MIN_SCALE = 1;
  // const topPx = 0;
  Transform(el);
  let initScale = 1;
  new AlloyFinger(el, {
    multipointStart() {
      To.stopAll();
      initScale = el.scaleX;
    },
    pinch(evt) {
      el.scaleX = initScale * evt.zoom;
      el.scaleY = initScale * evt.zoom;
    },
    multipointEnd() {
      To.stopAll();
      // 过小还原
      if (el.scaleX < MIN_SCALE) {
        new To({ el, property: 'scaleX', value: MIN_SCALE });
        new To({ el, property: 'scaleY', value: MIN_SCALE });
        new To({ el, property: 'translateX', value: 0 });
        new To({ el, property: 'translateY', value: 0 });
        return;
      }
      // 过大还原
      if (el.scaleX > MAX_SCALE) {
        new To({ el, property: 'scaleX', value: MAX_SCALE });
        new To({ el, property: 'scaleY', value: MAX_SCALE });
        return;
      }

      const overHeight = (el.height * el.scaleY - document.body.offsetHeight) / 2;
      const saveX = (el.width / 2) * (el.scaleX - 1);
      const saveY = overHeight > 0
        ? Math.min(overHeight, (el.height / 2) * (el.scaleY - 1))
        : (el.height / 2) * (el.scaleY - 1);
      const saveTranslateX = [-saveX, saveX];
      const saveTranslateY = [-saveY, saveY];
      // 左右 偏移修复
      if (el.translateX < saveTranslateX[0]) {
        new To({ el, property: 'translateX', value: saveTranslateX[0] });
      }
      if (el.translateX > saveTranslateX[1]) {
        new To({ el, property: 'translateX', value: saveTranslateX[1] });
      }
      // 上下偏移修复
      if (el.translateY < saveTranslateY[0]) {
        new To({ el, property: 'translateY', value: saveTranslateY[0] });
      }
      if (el.translateY > saveTranslateY[1]) {
        new To({ el, property: 'translateY', value: saveTranslateY[1] });
      }
    },
    pressMove(evt) {
      const defaultScaleX = 1;
      // 没放大不支持单指滑动
      if (el.scaleX === defaultScaleX) return;
      // 图片高度超过屏幕了才允许纵向拖动
      if (el.height * el.scaleY > window.screen.height) {
        el.translateY += evt.deltaY;
      }
      el.translateX += evt.deltaX * 0.8;
      evt.preventDefault();

      // 由于组件自带滑动翻页，那么就需要在当前图展示完之前禁止 滑动翻页，不然会导致图片没展示完
      const saveWidth = (el.scaleX - 1) * el.width / 2;

      if (Math.abs(el.translateX) < saveWidth) {
        evt.stopPropagation();
      }
    },
    // doubleTap(evt) {
    //   To.stopAll();
    //   if (el.scaleX > 1.5) {
    //     new To({ el, property: 'scaleX', value: 1 });
    //     new To({ el, property: 'scaleY', value: 1 });
    //     new To({ el, property: 'translateX', value: 0 });
    //     new To({ el, property: 'translateY', value: 0 });
    //   } else {
    //     const box = el.getBoundingClientRect();
    //     const y = box.height - ((evt.changedTouches[0].pageY - topPx) * 2)
    //     - (box.height / 2 - (evt.changedTouches[0].pageY - topPx));

    //     const x = box.width - ((evt.changedTouches[0].pageX) * 2) - (box.width / 2 - (evt.changedTouches[0].pageX));
    //     new To({ el, property: 'scaleX', value: 2 });
    //     new To({ el, property: 'scaleY', value: 2 });
    //     new To({ el, property: 'translateX', value: x });
    //     new To({ el, property: 'translateY', value: y });
    //   }
    // },
  });
};

export default transformImg;
