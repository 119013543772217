/* eslint-disable react/display-name */
import React, { Component, Fragment, createRef } from 'react';
import PropTypes from 'prop-types';
import  { createPortal } from 'react-dom';
import transformImg from './transformImg';
import { Mask } from 'components/mask';

import './style.less';

const withBrowserImage = (WrappedComponent) => {
  const removeThumb = src => src.replace('_thumb', '');
  class BrowserImage extends Component {
    constructor(props) {
      super(props);
      this.imgRef = createRef();
      this.state = {
        showBrowser: !!props.showBrowser || false,
      };
    }

    componentDidMount() {
      transformImg(this.imgRef.current);
    }

    componentDidUpdate() {
      transformImg(this.imgRef.current);
    }

    openBrowser = (event) => {
      // 阻止冒泡，避免再次触发外层 onClick
      event.stopPropagation();
      event.preventDefault();
      this.setState({ showBrowser: true });
    }

    closeBrowser(event) {
      // 阻止冒泡，避免再次触发外层 onClick 导致执行了 openBrowser
      event.stopPropagation();
      event.preventDefault();
      if (this.props.onClose) {
        this.props.onClose();
      }
      this.setState({ showBrowser: false });
    }


    render() {
      const { state, props } = this;

      return (
        <Fragment>
          {WrappedComponent && <WrappedComponent {...props} onClick={this.openBrowser} />}
          {state.showBrowser && createPortal(
            <Mask onClick={event => this.closeBrowser(event)} dark={true} >
              <div className="browser-image-mask">
                <div className="browser-image-container">
                  <img alt="放大后的图片" src={props.largeUrl || removeThumb(props.src)} ref={this.imgRef} />
                </div>
              </div>
            </Mask>
            , document.body,
          )}
        </Fragment>
      );
    }
  }

  BrowserImage.propTypes = {
    src: PropTypes.string,
    onClose: PropTypes.func,
    showBrowser: PropTypes.bool,
    largeUrl: PropTypes.string,
  };
  return BrowserImage;
};
export { withBrowserImage };
