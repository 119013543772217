/** 处理超过1千的数字转化thousands */
export const formatThousandsNumber = (num: number) => {
  const saveNum = Number(num);
  if (Number.isNaN(saveNum)) return num;
  if (saveNum < 1000) return saveNum;
  if (saveNum > 10000) return '10k+';
  return `${(saveNum / 1000).toFixed(1)}k`;
};


/** 给手机号做隐私处理，中间4位替换成**** */

export const mixUpPhoneNumber = phoneNumber => phoneNumber.replace(/^(\d{4})\d{4}(\d+)/, '$1****$2');
