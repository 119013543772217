(function () {
  let lastTime = 0;
  const vendors = ['webkit', 'moz'];
  for (let x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
    window.requestAnimationFrame = window[`${vendors[x]}RequestAnimationFrame`];
    window.cancelAnimationFrame = window[`${vendors[x]}CancelAnimationFrame`] || window[`${vendors[x]}CancelRequestAnimationFrame`];
  }

  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame = function (callback) {
      const currTime = new Date().getTime();
      const timeToCall = Math.max(0, 16 - (currTime - lastTime));
      const id = window.setTimeout(
        () => {
          callback(currTime + timeToCall);
        },
        timeToCall,
      );
      lastTime = currTime + timeToCall;
      return id;
    };
  }

  if (!window.cancelAnimationFrame) {
    window.cancelAnimationFrame = function (id) {
      clearTimeout(id);
    };
  }
}());


function defaultEase(x) {
  return Math.sqrt(1 - (x - 1) ** 2);
}

class To {
  constructor({ el, property, value, time = 500, ease = defaultEase, onEnd, onChange }) {
    this.el = el;
    this.property = property;
    this.value = value;
    this.time = time;
    this.ease = ease;
    this.onEnd = onEnd;
    this.onChange = onChange;
    this.beginTime = new Date();

    this.current = el[property];
    this.dv = value - this.current;
    this.toTick();
    To.List.push(this);
  }

  toTick = () => {
    const dt = new Date() - this.beginTime;
    if (dt >= this.time) {
      this.el[this.property] = this.value;
      this.onChange && this.onChange(this.value);
      this.onEnd && this.onEnd(this.value);
      cancelAnimationFrame(self.tickID);
      self.toTick = null;
      return;
    }
    this.el[this.property] = this.dv * this.ease(dt / this.time) + this.current;
    self.tickID = requestAnimationFrame(this.toTick);
    // self.tickID = requestAnimationFrame(toTick);
    // cancelAnimationFrame±ØÐëÔÚ tickID = requestAnimationFrame(toTick);µÄºóÃæ
    this.onChange && this.onChange(this.el[this.property]);
  }
}

To.List = [];

To.stopAll = function () {
  for (let i = 0, len = To.List.length; i < len; i++) {
    cancelAnimationFrame(To.List[i].tickID);
  }
  To.List.length = 0;
};

To.stop = function (to) {
  cancelAnimationFrame(to.tickID);
};
export default To;
