
const handle = function(entries, observer) {
    entries.forEach((entry) => {

        if (!entry.isIntersecting) {
            return;
        }

        let lazyImage = entry.target;

        if (!lazyImage.dataset.src) {
            return;
        }

        lazyImage.src = lazyImage.dataset.src;

        if (lazyImage.dataset.srcset) {
            lazyImage.srcset = lazyImage.dataset.srcset;
        }

        lazyImage.classList.remove('lazy');
        lazyImageObserver.unobserve(lazyImage);
    });
};

const lazyImageObserver = (function () {

    // 需要用 window.IntersectionObserver 而不能直接 IntersectionObserver，否则 iOS9 会报错
    // ReferenceError: Can't find variable: IntersectionObserver
    if (!window.IntersectionObserver) {

        console.log('LazyImage 依赖 IntersectionObserver API，当前 runtime 不包含该 API，请检查 polyfill');

        return {
            observe: function () {},
            unobserve: function () {}
        };
    }

    return new IntersectionObserver(handle);

})();

export default lazyImageObserver;
